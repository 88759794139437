import { useState, useEffect, useCallback } from 'react'
import useDebounce from '../../../hooks/useDebounce';
import axios from 'axios'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'

const DuplicateBreedingSchemeForm = (props) => {
    const [searchBreedingPipelineValue, setSearchBreedingPipelineValue] = useState("")
    const [breedingPipelineOptions, setBreedingPipelineOptions] = useState([])
    const [breedingPipelineLoading, setBreedingPipelineLoading] = useState(false)
    const [selectedBreedingPipeline, setSelectedBreedingPipeline] = useState({})
    const debouncedValue = useDebounce(searchBreedingPipelineValue, 500)

    const fetchBreedingPipeline = useCallback(async () => {
        const { data } = await axios.post(`/api/breeding-pipeline/`,
            {
                crop: props.breedingPipeline?.crop, organisation: props.breedingPipeline?.organisation, search: debouncedValue
            })
        if (data.success) {
            setBreedingPipelineOptions(data.filteredResult)
        }
        setBreedingPipelineLoading(false)

    }, [debouncedValue, props]);

    useEffect(() => {
        fetchBreedingPipeline()
    }, [debouncedValue, props, fetchBreedingPipeline])

    const handleSubmit = (e) => {
        e.preventDefault()
        props.handleAction(selectedBreedingPipeline)
    }
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.contentTitle}</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.contentText}
                    </DialogContentText>
                    <Autocomplete
                        id="BreedingPipelineId"
                        name="BreedingPipelineId"
                        filterOptions={(options, state) => options}
                        openOnFocus
                        options={breedingPipelineOptions}
                        getOptionLabel={(option) => `${option.name} (${option.breedingPipelineId})`}
                        renderOption={(option) => (
                            <Box>
                                <b>{option.name}</b> - <i>{option.crop}</i><br />
                                <small>{option.shortName}</small><br />
                                <small>{option.breedingPipelineId}</small> | <small>{option.organisation}</small>
                            </Box>
                        )}
                        onInputChange={(e, newValue) => {
                            setBreedingPipelineLoading(true)
                            setSearchBreedingPipelineValue(newValue)
                        }}
                        onChange={(e, selectedValue) => {
                            setSelectedBreedingPipeline(selectedValue)
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size='small'
                                required
                                label="Select Breeding Pipeline"
                                variant="outlined"
                                margin='dense'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {breedingPipelineLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button type='submit' color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default DuplicateBreedingSchemeForm;