import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
    getBreedingSchemes, createBreedingScheme, updateBreedingScheme,
    cancelDuplicateBreedingScheme, confirmDuplicateBreedingScheme, duplicateBreedingScheme,
    cancelRemoveBreedingScheme, confirmRemoveBreedingScheme, removeBreedingScheme
} from '../../../actions/breedingScheme'
import { showNotification } from '../../../actions/notification'
import { format } from 'date-fns'
import { breedingSchemeLookups } from '../../../constants'
import Box from '@material-ui/core/Box'
import MaterialTable from 'material-table'
import { MTableBodyRow, MTableAction, MTableEditField, MTableToolbar } from 'material-table'
import TableIcons from '../../molecules/TableIcons'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import UserDialog from '../../molecules/UserDialog'
import DuplicateBreedingSchemeForm from '../DuplicateBreedingSchemeForm';
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableCellTooltip from '../../molecules/TableCellTooltip'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import BreedingSchemeToolbar from '../BreedingSchemeToolbar';
import BreedingSchemeWizard from '../BreedingSchemeWizard';
import TableToExcel from "@linways/table-to-excel";
import { LOADING_BREEDING_STAGE_EXPORT, REMOVE_LOADING_BREEDING_STAGE_EXPORT } from '../../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
    table: {
        '& th': {
            border: "1px solid #e3e3e3",
            background: "#ecf0f5"
        },
        '& td': {
            padding: "2px 24px 2px 16px",
            fontSize: "13px !important",
            border: "1px solid #e3e3e3",
        },
        '& tbody > tr > td:first-child': {
            width: "30px !important"
        },
        '& td input': {
            width: "100%"
        },
        '& td > div': {
            width: "auto !important"
        },
        '& .breeding-scheme-toolbar > .MuiToolbar-root': {
            //minHeight: "25px",
            display: "inline-block",
            //float: "left",
            marginRight: "15px",
            paddingTop: "15px"
        }
    },
    rowTooltip: {
        width: "100px"
    }
}))


const AutocompleteInput = (cellProps) => {
    const autocompleteOptions = Object.keys(cellProps.columnDef.lookup)
    const [selectedValue] = useState(cellProps.value)
    const classes = useStyles()
    return (
        <Autocomplete
            id={cellProps.columnDef.field}
            freeSolo
            fullWidth
            style={{ width: "150px", margin: "0px" }}
            options={autocompleteOptions.map((option) => option)}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            defaultValue={selectedValue}
            onInputChange={(e, newValue) => {
                cellProps.onChange(newValue)
            }}
            renderInput={(params) => (
                <TextField {...params}
                    margin="normal"
                    className={classes.soloAutocomplete}
                    variant="standard"
                    fullWidth
                />
            )}
            renderOption={(option) => (
                <Box flex={1}>
                    <Box flexGrow={1}><Typography>{option}</Typography></Box>
                </Box>
            )}
        />
    )
}

const BreedingScheme = ({ sharedOnly, handleTourOpen }) => {
    const tableRef = useRef()
    const userAuth = useSelector((state) => state.userAuth)
    const breedingSchemeState = useSelector((state) => state.breedingScheme)
    const [modalForm, setModalForm] = useState({ open: false, loading: false, formData: {} })
    const [privateOnly, setPrivateOnly] = useState(false)
    const [tablePageSize, setTablePageSize] = useState(10)
    const dispatch = useDispatch()
    const history = useHistory()

    const columns = [
        { title: 'Breeding Pipeline Id', field: 'BreedingPipeline.breedingPipelineId', align: "center", cellStyle: { fontWeight: "bold", minWidth: "120px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, editable: "never" },
        { title: 'Organization', field: 'BreedingPipeline.organisation', align: "center", cellStyle: { minWidth: "130px" }, editable: "never" },
        { title: 'Target Product Profile Category', field: 'tier', align: "center", lookup: breedingSchemeLookups.tier },
        { title: 'Crop', field: 'BreedingPipeline.crop', align: "center", editable: "never" },
        { title: 'Product Type', field: 'productType', align: "center", lookup: breedingSchemeLookups.productType, editable: "never" },
        { title: 'Creator', field: 'User.fullName', align: "center", editable: "never" },
        { title: 'Name', field: 'name', align: "center", cellStyle: { maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, editable: "never" },
    ];

    const getBreedingSchemeData = query => {
        setTablePageSize(query.pageSize)
        query.privateOnly = privateOnly
        return new Promise((resolve, reject) => {
            Promise.resolve(dispatch(getBreedingSchemes(query))
                .then((breedingSchemes) => {
                    const TableData = breedingSchemes.data.rows
                    const totalCount = breedingSchemes.data.count
                    resolve({
                        data: TableData,
                        page: query.page,
                        totalCount,
                    });
                }))
        });
    };

    const handleAddBreedingScheme = () => {
        setModalForm({
            open: true,
            formData: {
                UserId: userAuth.user.id,
                Creator: userAuth.user.fullName,
                BreedingPipelineId: "",
                tier: "",
                productType: "",
                traits: [
                    //Sample JSON format will look like this
                    // {
                    //     TraitCatalogId: 000000,
                    //     traitType: "Quality",
                    // }
                ]
            }
        })
    }

    const handleWizardFormChanges = (formData) => {
        setModalForm({
            ...modalForm,
            formData: {
                ...modalForm.formData,
                ...formData
            }
        })
    }

    const handleCreateBreedingScheme = async (breedingSchemeObj) => {
        setModalForm({ ...modalForm, loading: true })
        await dispatch(createBreedingScheme(breedingSchemeObj))
        setModalForm({ ...modalForm, loading: false, open: false })
        reloadGrid()
    }

    const handleCloseBreedingSchemeModal = () => {
        setModalForm({
            ...modalForm,
            open: false
        })
    }

    const handleToggleVisibility = () => {
        setPrivateOnly(!privateOnly)
        reloadGrid()
    }

    // Passed argument is the new selected breeding pipeline id to be duplicate to
    const handleDuplicateBreedingScheme = async (breedingPipelineObj) => {
        await dispatch(duplicateBreedingScheme(breedingSchemeState.duplicateSchemeForm.breedingSchemeId, breedingPipelineObj))
        reloadGrid()
    }

    const handleCancelDuplicateBreedingScheme = () => {
        dispatch(cancelDuplicateBreedingScheme())
    }

    const handleRemoveBreedingScheme = async () => {
        await dispatch(removeBreedingScheme(breedingSchemeState.removeSchemeForm.breedingSchemeId, breedingSchemeState.removeSchemeForm.breedingPipelineId, { sharedOnly: sharedOnly }))
        reloadGrid()
    }

    const handleCancelRemoveBreedingScheme = () => {
        dispatch(cancelRemoveBreedingScheme())
    }

    const handleExportToCSV = async () => {
        await dispatch({ type: LOADING_BREEDING_STAGE_EXPORT })
        let table = document.getElementsByClassName("MuiTable-root");
        setTimeout(async () => {
            await TableToExcel.convert(table[0], {
                name: `BreedingSchemes.xlsx`,
                sheet: {
                    name: 'BreedingSchemes'
                }
            });
            await dispatch({ type: REMOVE_LOADING_BREEDING_STAGE_EXPORT })
        }, 1500)
    }

    const reloadGrid = () => {
        tableRef.current && tableRef.current.onQueryChange()
    }

    const classes = useStyles()
    return (
        <Box px={2} className={classes.table} flexGrow={1} style={{ maxWidth: "92vw" }}>
            {modalForm.open && <BreedingSchemeWizard modalFormState={modalForm} handleWizardFormChanges={handleWizardFormChanges} handleCloseModal={handleCloseBreedingSchemeModal} handleSubmit={handleCreateBreedingScheme} />}
            <DuplicateBreedingSchemeForm
                open={breedingSchemeState.duplicateSchemeForm.open}
                breedingPipeline={breedingSchemeState.duplicateSchemeForm.breedingPipeline}
                contentTitle="Are you sure you want to create a copy of the selected breeding scheme?"
                contentText="Note: Breeding Scheme Settings and Breeding Stages under the selected Breeding Scheme will also be copied"
                handleAction={handleDuplicateBreedingScheme}
                handleCancel={handleCancelDuplicateBreedingScheme}
            />
            <UserDialog
                open={breedingSchemeState.removeSchemeForm.open}
                contentTitle="Are you sure you want to remove the selected breeding scheme?"
                contentText=""
                handleAction={handleRemoveBreedingScheme}
                handleCancel={handleCancelRemoveBreedingScheme}
            />
            <MaterialTable
                title="Breeding Schemes"
                tableRef={tableRef}
                columns={columns}
                icons={TableIcons}
                isLoading={breedingSchemeState.isLoading}
                options={{
                    pageSize: tablePageSize,
                    debounceInterval: 1500,
                    //Added to assist the UI experience during searching but raised unpleasant experience on other fronts
                    //searchAutoFocus: true,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: false,
                    columnsButton: false,
                    addRowPosition: "first",
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                    rowStyle: (rowData) => ({
                        backgroundColor: rowData.UserId !== userAuth.user.id ? "#f1f1f1" : "inherit"
                    })
                }}
                data={query => getBreedingSchemeData(query)}
                components={{
                    Toolbar: props => (
                        <>
                            <MTableToolbar {...props} />
                            <BreedingSchemeToolbar
                                className="appTour-step-2"
                                handleAddBreedingStage={handleAddBreedingScheme}
                                handleRefreshBreedingStage={reloadGrid}
                                handleToggleVisibility={handleToggleVisibility}
                                handleExportToCSV={handleExportToCSV}
                                isPrivateOnly={privateOnly}
                                handleTourOpen={handleTourOpen}
                            />
                        </>
                    ),
                    Row: rowProps => {
                        const breedingPipelineName = rowProps.data.BreedingPipeline.name + " (" + rowProps.data.BreedingPipeline.breedingPipelineId + ")"
                        const breedingSchemeName = rowProps.data.name
                        const createdBy = rowProps.data.User.fullName
                        const createdAt = format(new Date(rowProps.data.createdAt), 'MMM dd, yyyy')

                        const ToolTipTitle = ({ breedingPipelineName, breedingSchemeName, createdBy, createdAt }) => {
                            return (
                                <Box lineHeight={2}>
                                    <u>Breeding Pipeline</u>: <b>{breedingPipelineName}</b><br />
                                    <u>Breeding Scheme</u>: <b>{breedingSchemeName}</b><br />
                                    <u>Created By</u>: <b>{createdBy}</b><br />
                                    <u>Created At</u>: <b>{createdAt}</b>
                                </Box>
                            )
                        }
                        return (
                            <TableCellTooltip title={
                                <ToolTipTitle breedingPipelineName={breedingPipelineName} breedingSchemeName={breedingSchemeName} createdAt={createdAt} createdBy={createdBy} />}>
                                <MTableBodyRow {...rowProps} />
                            </TableCellTooltip>
                        )
                    },
                    Action: actionProps =>
                        <span style={{ color: "#000" }}>
                            <MTableAction {...actionProps} />
                        </span>,
                    EditField: cellProps =>
                        (['treatment'].includes(cellProps.columnDef.field)) ?
                            <AutocompleteInput {...cellProps} /> : <MTableEditField {...cellProps} />
                }}
                actions={
                    [
                        rowData => ({
                            icon: () => <ViewCompactIcon color={"secondary"} />,
                            isFreeAction: false,
                            onClick: (event) => history.push(`/breeding-stage/${rowData.id}`)
                        }),
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Duplicate Breeding Scheme",
                            isFreeAction: false,
                            //- TODO - Verify if user is owner or not
                            //disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            //hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmDuplicateBreedingScheme(rowData.id, rowData.BreedingPipeline))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Breeding Scheme",
                            isFreeAction: false,
                            hidden: userAuth.user.id !== rowData.UserId && userAuth.user.role !== "admin",
                            //- TODO - Verify if user is owner or not
                            //disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,                            
                            onClick: (event) => dispatch(confirmRemoveBreedingScheme(rowData.id))
                        })
                    ]}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            const updatedBreedingSchemeObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            if (rowData.UserId !== userAuth.user.id && userAuth.user.role !== "admin") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to make changes to this breeding scheme" }))
                                reject()
                            } else {
                                dispatch(updateBreedingScheme(updatedBreedingSchemeObj))
                                resolve(reloadGrid())
                            }
                        });
                    }
                }}
            />
        </Box >
    )
}

export default BreedingScheme;