import { useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    createBreedingStage, updateBreedingStage, confirmDuplicateBreedingStage, orderBreedingStage,
    confirmRemoveBreedingStage, openAddTraits, refreshBreedingStagesTable, openUpdateTraits, confirmRemoveTraits, confirmRemoveApplication,
    addApplication, openAddGenotypeTechnology, confirmRemoveTechnology, openAddMarkerTraits, confirmRemoveMarkerTraits, openAddPhenotypeTraits, confirmRemovePhenotypeTraits, openQuickNote
} from '../../../actions/breedingStage'
import MaterialTable, { MTableToolbar } from 'material-table'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import GridCell from "../GridCell"
import GridEditableCell from "../GridEditableCell"
//import BreedingStageSummary from '../BreedingStageSummary'
import TableIcons from '../../molecules/TableIcons'
import TableRowOrder from '../../molecules/TableRowOrder'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import makeStyles from '@material-ui/core/styles/makeStyles'
import BreedingStageToolbar from '../BreedingStageToolbar'
import BreedingStageColumns from '../BreedingStageColumns'
import { flattenBreedingSchemeData } from "../../../utils"
import { LOADING_BREEDING_STAGE_EXPORT, REMOVE_LOADING_BREEDING_STAGE_EXPORT } from '../../../constants/actionTypes'
//import html2canvas from 'html2canvas'
//import jsPDF from 'jspdf'
import TableToExcel from "@linways/table-to-excel";

const useStyles = makeStyles((theme) => ({
    table: {
        '& div > div > div > div ': {
            marginBottom: "10px"
        },
        '& .breeding-stage-toolbar > .MuiToolbar-root': {
            minHeight: "25px",
            display: "inline-block",
            float: "left",
            marginRight: "15px",
            paddingTop: "15px"
        },
        '& .MuiToolbar-regular  button': {
            padding: "8px 12px"
        },
        '& tbody > tr > td:first-child, thead > tr:first-child > th:first-child': {
            position: "sticky",
            left: 0,
            backgroundColor: "#fff",
            zIndex: 10
        },
        '& th': {
            fontSize: "0.79rem",
            padding: "4px 10px",
            border: "1px solid #b3b3b3",
            borderBottom: "2px solid #b3b3b3"
        },
        '& .verticalTitle': {
            padding: "8px",
            textAlign: "-webkit-center",
            verticalAlign: "bottom",
            height: "250px",
            whiteSpace: "nowrap",
        },
        '& .verticalTitle > div': {
            transform: " rotate(270deg)",
            width: "25px",
        },
        '& .MuiTableRow-root': {
            height: "0px !important"
        },
        '& .MuiTableRow-footer': {
            height: "auto !important"
        }
    }
}))

const renderColumnsPerProductType = (breedingStage) => {
    const structuredColumns = {
        Line: {
            PopulationStructure: {
                matingDesign: breedingStage?.PopulationStructure.matingDesign,
                numberOfCrosses: breedingStage?.PopulationStructure.numberOfCrosses,
                numberOfIndividualsPerCross: breedingStage?.PopulationStructure.numberOfIndividualsPerCross,
                numberOfLinesPerIndividual: breedingStage?.PopulationStructure.numberOfLinesPerIndividual,
                numberOfSublinesPerLine: breedingStage?.PopulationStructure.numberOfSublinesPerLine,
                numberOfIndividuals: breedingStage?.PopulationStructure.numberOfIndividuals,
                totalNumberOfGenotypes: breedingStage?.PopulationStructure.totalNumberOfGenotypes,
            },
            MaterialIncrease: {
                multiplicationUnit: breedingStage?.MaterialIncrease.multiplicationUnit,
                multiplicationTechnology: breedingStage?.MaterialIncrease.multiplicationTechnology,
                typeOfExperimentalUnit: breedingStage?.MaterialIncrease.typeOfExperimentalUnit,
                length: breedingStage?.MaterialIncrease.length,
                width: breedingStage?.MaterialIncrease.width,
                numberOfExperimentalUnits: breedingStage?.MaterialIncrease.numberOfExperimentalUnits
            },
            FieldTrialDesign: {
                experimentalUnit: {
                    fieldCondition: breedingStage?.FieldTrialDesign.fieldCondition,
                    fieldTypeOfExperimentalUnit: breedingStage?.FieldTrialDesign.fieldTypeOfExperimentalUnit,
                    fieldLength: breedingStage?.FieldTrialDesign.fieldLength,
                    fieldWidth: breedingStage?.FieldTrialDesign.fieldWidth
                },
                layout: {
                    experimentalDesign: breedingStage?.FieldTrialDesign.experimentalDesign,
                    numberOfLocations: breedingStage?.FieldTrialDesign.numberOfLocations,
                    numberOfReplicatesPerLocation: breedingStage?.FieldTrialDesign.numberOfReplicatesPerLocation,
                    numberOfExperimentalUnitsPerLocation: breedingStage?.FieldTrialDesign.numberOfExperimentalUnitsPerLocation,
                    totalNumberOfExperimentalUnits: breedingStage?.FieldTrialDesign.totalNumberOfExperimentalUnits
                },
                checkStrategy: {
                    numberOfSpatialCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfSpatialCheckGenotypes,
                    numberOfTemporalCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfTemporalCheckGenotypes,
                    checkUnitsPerLocation: breedingStage?.FieldTrialDesign.checkUnitsPerLocation,
                    percentageOfCheckUnitsPerLocation: breedingStage?.FieldTrialDesign.percentageOfCheckUnitsPerLocation,
                    trialsConnectedVia: breedingStage?.FieldTrialDesign.trialsConnectedVia
                }
            }
        },
        Clone: {
            PopulationStructure: {
                matingDesign: breedingStage?.PopulationStructure.matingDesign,
                numberOfCrosses: breedingStage?.PopulationStructure.numberOfCrosses,
                numberOfIndividualsPerCross: breedingStage?.PopulationStructure.numberOfIndividualsPerCross,
                numberOfIndividuals: breedingStage?.PopulationStructure.numberOfIndividuals,
                totalNumberOfGenotypes: breedingStage?.PopulationStructure.totalNumberOfGenotypes,
            },
            MaterialIncrease: {
                multiplicationUnit: breedingStage?.MaterialIncrease.multiplicationUnit,
                multiplicationTechnology: breedingStage?.MaterialIncrease.multiplicationTechnology,
                typeOfExperimentalUnit: breedingStage?.MaterialIncrease.typeOfExperimentalUnit,
                length: breedingStage?.MaterialIncrease.length,
                width: breedingStage?.MaterialIncrease.width,
                numberOfPlantsPerExperimentalUnit: breedingStage?.MaterialIncrease.numberOfPlantsPerExperimentalUnit,
                numberOfExperimentalUnits: breedingStage?.MaterialIncrease.numberOfExperimentalUnits
            },
            FieldTrialDesign: {
                experimentalUnit: {
                    fieldCondition: breedingStage?.FieldTrialDesign.fieldCondition,
                    fieldTypeOfExperimentalUnit: breedingStage?.FieldTrialDesign.fieldTypeOfExperimentalUnit,
                    fieldLength: breedingStage?.FieldTrialDesign.fieldLength,
                    fieldWidth: breedingStage?.FieldTrialDesign.fieldWidth,
                    fieldNumberOfPlantsPerExperimentalUnit: breedingStage?.FieldTrialDesign.fieldNumberOfPlantsPerExperimentalUnit
                },
                layout: {
                    experimentalDesign: breedingStage?.FieldTrialDesign.experimentalDesign,
                    numberOfLocations: breedingStage?.FieldTrialDesign.numberOfLocations,
                    numberOfReplicatesPerLocation: breedingStage?.FieldTrialDesign.numberOfReplicatesPerLocation,
                    numberOfExperimentalUnitsPerLocation: breedingStage?.FieldTrialDesign.numberOfExperimentalUnitsPerLocation,
                    totalNumberOfExperimentalUnits: breedingStage?.FieldTrialDesign.totalNumberOfExperimentalUnits
                },
                checkStrategy: {
                    numberOfSpatialCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfSpatialCheckGenotypes,
                    numberOfTemporalCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfTemporalCheckGenotypes,
                    checkUnitsPerLocation: breedingStage?.FieldTrialDesign.checkUnitsPerLocation,
                    percentageOfCheckUnitsPerLocation: breedingStage?.FieldTrialDesign.percentageOfCheckUnitsPerLocation,
                    trialsConnectedVia: breedingStage?.FieldTrialDesign.trialsConnectedVia
                }
            }
        },
        Hybrid: {
            PopulationStructure: {
                matingDesign: breedingStage?.PopulationStructure.matingDesign,
                numberOfCrosses: breedingStage?.PopulationStructure.numberOfCrosses,
                numberOfIndividualsPerCross: breedingStage?.PopulationStructure.numberOfIndividualsPerCross,
                numberOfTesters: breedingStage?.PopulationStructure.numberOfTesters,
                numberOfTestcrosses: breedingStage?.PopulationStructure.numberOfTestcrosses,
                numberOfHybrids: breedingStage?.PopulationStructure.numberOfHybrids,
                totalNumberOfGenotypes: breedingStage?.PopulationStructure.totalNumberOfGenotypes,
            },
            MaterialIncrease: {
                multiplicationUnit: breedingStage?.MaterialIncrease.multiplicationUnit,
                multiplicationTechnology: breedingStage?.MaterialIncrease.multiplicationTechnology,
                typeOfExperimentalUnit: breedingStage?.MaterialIncrease.typeOfExperimentalUnit,
                length: breedingStage?.MaterialIncrease.length,
                width: breedingStage?.MaterialIncrease.width,
                numberOfExperimentalUnits: breedingStage?.MaterialIncrease.numberOfExperimentalUnits
            },
            FieldTrialDesign: {
                experimentalUnit: {
                    fieldCondition: breedingStage?.FieldTrialDesign.fieldCondition,
                    fieldTypeOfExperimentalUnit: breedingStage?.FieldTrialDesign.fieldTypeOfExperimentalUnit,
                    fieldLength: breedingStage?.FieldTrialDesign.fieldLength,
                    fieldWidth: breedingStage?.FieldTrialDesign.fieldWidth
                },
                layout: {
                    experimentalDesign: breedingStage?.FieldTrialDesign.experimentalDesign,
                    numberOfLocations: breedingStage?.FieldTrialDesign.numberOfLocations,
                    numberOfReplicatesPerLocation: breedingStage?.FieldTrialDesign.numberOfReplicatesPerLocation,
                    numberOfExperimentalUnitsPerLocation: breedingStage?.FieldTrialDesign.numberOfExperimentalUnitsPerLocation,
                    totalNumberOfExperimentalUnits: breedingStage?.FieldTrialDesign.totalNumberOfExperimentalUnits
                },
                checkStrategy: {
                    numberOfSpatialCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfSpatialCheckGenotypes,
                    numberOfTemporalCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfTemporalCheckGenotypes,
                    checkUnitsPerLocation: breedingStage?.FieldTrialDesign.checkUnitsPerLocation,
                    percentageOfCheckUnitsPerLocation: breedingStage?.FieldTrialDesign.percentageOfCheckUnitsPerLocation,
                    trialsConnectedVia: breedingStage?.FieldTrialDesign.trialsConnectedVia
                }
            }
        },
        "Open-Pollinated Variety": {
            PopulationStructure: {
                typeOfParent: breedingStage?.PopulationStructure.typeOfParent,
                matingDesign: breedingStage?.PopulationStructure.matingDesign,
                numberOfHalfSibFamilies: breedingStage?.PopulationStructure.numberOfHalfSibFamilies,
                numberOfFullSibFamilies: breedingStage?.PopulationStructure.numberOfFullSibFamilies,
                numberOfPopulations: breedingStage?.PopulationStructure.numberOfPopulations,
                totalNumberOfGenotypes: breedingStage?.PopulationStructure.totalNumberOfGenotypes,
            },
            MaterialIncrease: {
                multiplicationUnit: breedingStage?.MaterialIncrease.multiplicationUnit,
                multiplicationTechnology: breedingStage?.MaterialIncrease.multiplicationTechnology,
                typeOfExperimentalUnit: breedingStage?.MaterialIncrease.typeOfExperimentalUnit,
                length: breedingStage?.MaterialIncrease.length,
                width: breedingStage?.MaterialIncrease.width,
                numberOfExperimentalUnits: breedingStage?.MaterialIncrease.numberOfExperimentalUnits
            },
            FieldTrialDesign: {
                experimentalUnit: {
                    fieldCondition: breedingStage?.FieldTrialDesign.fieldCondition,
                    fieldTypeOfExperimentalUnit: breedingStage?.FieldTrialDesign.fieldTypeOfExperimentalUnit,
                    fieldLength: breedingStage?.FieldTrialDesign.fieldLength,
                    fieldWidth: breedingStage?.FieldTrialDesign.fieldWidth
                },
                layout: {
                    experimentalDesign: breedingStage?.FieldTrialDesign.experimentalDesign,
                    numberOfLocations: breedingStage?.FieldTrialDesign.numberOfLocations,
                    numberOfReplicatesPerLocation: breedingStage?.FieldTrialDesign.numberOfReplicatesPerLocation,
                    numberOfExperimentalUnitsPerLocation: breedingStage?.FieldTrialDesign.numberOfExperimentalUnitsPerLocation,
                    totalNumberOfExperimentalUnits: breedingStage?.FieldTrialDesign.totalNumberOfExperimentalUnits
                },
                checkStrategy: {
                    numberOfSpatialCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfSpatialCheckGenotypes,
                    numberOfTemporalCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfTemporalCheckGenotypes,
                    checkUnitsPerLocation: breedingStage?.FieldTrialDesign.checkUnitsPerLocation,
                    percentageOfCheckUnitsPerLocation: breedingStage?.FieldTrialDesign.percentageOfCheckUnitsPerLocation,
                    trialsConnectedVia: breedingStage?.FieldTrialDesign.trialsConnectedVia
                }
            }
        },
        Synthetic: {
            PopulationStructure: {
                typeOfParent: breedingStage?.PopulationStructure.typeOfParent,
                matingDesign: breedingStage?.PopulationStructure.matingDesign,
                numberOfHalfSibFamilies: breedingStage?.PopulationStructure.numberOfHalfSibFamilies,
                numberOfFullSibFamilies: breedingStage?.PopulationStructure.numberOfFullSibFamilies,
                numberOfTesters: breedingStage?.PopulationStructure.numberOfTesters,
                numberOfTestcrosses: breedingStage?.PopulationStructure.numberOfTestcrosses,
                numberOfComponentsPerSynthetic: breedingStage?.PopulationStructure.numberOfComponentsPerSynthetic,
                numberOfSynthetics: breedingStage?.PopulationStructure.numberOfSynthetics,
                totalNumberOfGenotypes: breedingStage?.PopulationStructure.totalNumberOfGenotypes,
            },
            MaterialIncrease: {
                multiplicationUnit: breedingStage?.MaterialIncrease.multiplicationUnit,
                multiplicationTechnology: breedingStage?.MaterialIncrease.multiplicationTechnology,
                typeOfExperimentalUnit: breedingStage?.MaterialIncrease.typeOfExperimentalUnit,
                length: breedingStage?.MaterialIncrease.length,
                width: breedingStage?.MaterialIncrease.width,
                numberOfExperimentalUnits: breedingStage?.MaterialIncrease.numberOfExperimentalUnits
            },
            FieldTrialDesign: {
                experimentalUnit: {
                    fieldCondition: breedingStage?.FieldTrialDesign.fieldCondition,
                    fieldTypeOfExperimentalUnit: breedingStage?.FieldTrialDesign.fieldTypeOfExperimentalUnit,
                    fieldLength: breedingStage?.FieldTrialDesign.fieldLength,
                    fieldWidth: breedingStage?.FieldTrialDesign.fieldWidth,
                },
                layout: {
                    experimentalDesign: breedingStage?.FieldTrialDesign.experimentalDesign,
                    numberOfLocations: breedingStage?.FieldTrialDesign.numberOfLocations,
                    numberOfReplicatesPerLocation: breedingStage?.FieldTrialDesign.numberOfReplicatesPerLocation,
                    numberOfExperimentalUnitsPerLocation: breedingStage?.FieldTrialDesign.numberOfExperimentalUnitsPerLocation,
                    totalNumberOfExperimentalUnits: breedingStage?.FieldTrialDesign.totalNumberOfExperimentalUnits
                },
                checkStrategy: {
                    numberOfSpatialCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfSpatialCheckGenotypes,
                    numberOfTemporalCheckGenotypes: breedingStage?.FieldTrialDesign.numberOfTemporalCheckGenotypes,
                    checkUnitsPerLocation: breedingStage?.FieldTrialDesign.checkUnitsPerLocation,
                    percentageOfCheckUnitsPerLocation: breedingStage?.FieldTrialDesign.percentageOfCheckUnitsPerLocation,
                    trialsConnectedVia: breedingStage?.FieldTrialDesign.trialsConnectedVia
                }
            }
        }
    }
    return structuredColumns
}

const genotypingTechnologyApplicationIterator = (applications) => {
    let applicationObj = {}
    applications.forEach((app, idx) => { applicationObj[app.columnName + " " + (idx + 1)] = app })
    return applicationObj
}

const memoizedParams = ({ permission, breedingScheme, breedingStagesData, pipelineSettings, breedingSchemeId, poolId }) => {
    if (breedingStagesData.length)
        return {
            breedingStages: breedingStagesData,
            breedingSchemePermission: permission,
            breedingScheme,
            pipelineSettings,
            breedingSchemeId,
            poolId
        }
    else return {
        breedingStages: [],
        breedingSchemePermission: permission,
        breedingScheme,
        pipelineSettings,
        breedingSchemeId,
        poolId
    }
}

const BreedingStage = (props) => {
    const dispatch = useDispatch()
    // const [breedingStages, setBreedingStages] = useState(props.breedingStagesData)
    // const [breedingSchemePermission, setBreedingSchemePermission] = useState("VIEWER")
    const breedingStageColumns = useSelector((state) => state.breedingStageColumns)
    const tableRef = useRef()
    const { breedingStages, breedingSchemePermission, breedingScheme, pipelineSettings, breedingSchemeId, poolId } =
        useMemo(() => memoizedParams(props), [props.breedingStagesData, props.permission])
    // useEffect(() => {
    //     setBreedingStages(props.breedingStagesData)
    //     setBreedingSchemePermission(props.permission)
    // }, [props.breedingStagesData, props.permission])
    //Rearrange the Json Object Keys inorder to render in the same format in the Excel Template    
    let sortedColumnObj = {}
    if (breedingStages.length) {
        sortedColumnObj = {
            actions: "",
            stageNo: breedingStages[0].stageNo,
            BreedingPopulation: { ...breedingStages[0].BreedingPopulation },
            Divider1: breedingStages[0].PopulationStructure.id,
            PopulationStructure: {
                numberOfParents: {
                    recycledWithinPipeline: breedingStages[0].PopulationStructure.recycledWithinPipeline,
                    fromAnotherCGIARPipeline: breedingStages[0].PopulationStructure.fromAnotherCGIARPipeline,
                    fromNonCGIARPipelines: breedingStages[0].PopulationStructure.fromNonCGIARPipelines,
                    fromOlderVarieties: breedingStages[0].PopulationStructure.fromOlderVarieties,
                    fromLandracesOrOPVs: breedingStages[0].PopulationStructure.fromLandracesOrOPVs,
                    fromTDD: breedingStages[0].PopulationStructure.fromTDD,
                    totalNumberOfParents: breedingStages[0].PopulationStructure.totalNumberOfParents
                },
                //Render Columns based on the Breeding Scheme Product Type
                ...renderColumnsPerProductType(breedingStages[0])[breedingScheme.productType]?.PopulationStructure
            },
            Divider2: breedingStages[0].MaterialIncrease.id,
            MaterialIncrease: {
                ...renderColumnsPerProductType(breedingStages[0])[breedingScheme.productType]?.MaterialIncrease
            },
            Divider3: breedingStages[0].Genotyping.id,
            Genotyping: breedingStages[0].Genotyping.Technologies.map((technology, idx) => ({
                [technology.columnName]: {
                    noOfMarkers: technology.noOfMarkers,
                    groupId: technology.groupId,
                    ...genotypingTechnologyApplicationIterator(technology.Applications)
                }
            })),
            Divider4: breedingStages[0].MarkerSelection.id,
            MarkerSelection: {
                noOfMarkerAssistedSelection: breedingStages[0].MarkerSelection.MarkerTraits.map((markerTrait, idx) => ({
                    ["_ " + markerTrait.TraitCatalog.name]: markerTrait
                }))
            },
            Divider5: breedingStages[0].PhenotypeScreening.id,
            PhenotypeScreening: breedingStages[0].PhenotypeScreening.PhenotypeTraits.map((phenotypeTrait, idx) => ({
                ["- " + phenotypeTrait.TraitCatalog.name]: breedingScheme.productType === "Clone" ? phenotypeTrait : {
                    phenotypeCondition: phenotypeTrait.phenotypeCondition,
                    phenotypeTypeOfExpUnit: phenotypeTrait.phenotypeTypeOfExpUnit,
                    phenotypeLength: phenotypeTrait.phenotypeLength,
                    phenotypeWidth: phenotypeTrait.phenotypeWidth,
                    phenotypeNoOfLocations: phenotypeTrait.phenotypeNoOfLocations,
                    phenotypeNoOfRepPerLocation: phenotypeTrait.phenotypeNoOfRepPerLocation,
                    groupId: phenotypeTrait.groupId
                }
            })),
            Divider6: breedingStages[0].FieldTrialDesign.id,
            FieldTrialDesign: {
                ...renderColumnsPerProductType(breedingStages[0])[breedingScheme.productType]?.FieldTrialDesign
            },
            Divider7: breedingStages[0].SelectionStrategy.id,
            SelectionStrategy: {
                QualityTraits: breedingStages[0].SelectionStrategy.QualityTraits.map(qualityTrait => ({
                    [qualityTrait.TraitCatalog.name]: qualityTrait
                })),
                AgronomicTraits: breedingStages[0].SelectionStrategy.AgronomicTraits.map(agronomicTrait => ({
                    [agronomicTrait.TraitCatalog.name]: agronomicTrait
                })),
                MorphologicalTraits: breedingStages[0].SelectionStrategy.MorphologicalTraits.map(morphologicalTrait => ({
                    [morphologicalTrait.TraitCatalog.name]: morphologicalTrait
                })),
                PhenologicalTraits: breedingStages[0].SelectionStrategy.PhenologicalTraits.map(phenologicalTrait => ({
                    [phenologicalTrait.TraitCatalog.name]: phenologicalTrait
                })),
                AbioticStressTraits: breedingStages[0].SelectionStrategy.AbioticStressTraits.map(abioticStressTrait => ({
                    [abioticStressTrait.TraitCatalog.name]: abioticStressTrait
                })),
                BioticStressTraits: breedingStages[0].SelectionStrategy.BioticStressTraits.map(bioticStressTrait => ({
                    [bioticStressTrait.TraitCatalog.name]: bioticStressTrait
                }))
            },
            Divider8: breedingStages[0].Advancement.id,
            Advancement: {
                advancedUnit: breedingStages[0].Advancement.advancedUnit,
                numberOfUnitsAdvanced: breedingStages[0].Advancement.numberOfUnitsAdvanced,
                percentageOfUnitsAdvanced: breedingStages[0].Advancement.percentageOfUnitsAdvanced
            }
            // CrossingStrategy: {
            //     CrossingPools: breedingStages[0].CrossingStrategy.CrossingPools.map((pool, idx) => ({
            //         [pool.columnName + " " + (idx + 1)]: pool
            //     })),
            //     matingDesign: breedingStages[0].CrossingStrategy.matingDesign,
            // }
        }
    }

    const handleAddBreedingStage = () => {
        const initialBreedingStageData = {
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            Genotyping: breedingStages[0] ? breedingStages[0].Genotyping : {
                Technologies: []
            },
            MarkerSelection: breedingStages[0] ? breedingStages[0].MarkerSelection : {
                MarkerTraits: []
            },
            PhenotypeScreening: breedingStages[0] ? breedingStages[0].PhenotypeScreening : {
                PhenotypeTraits: []
            },
            SelectionStrategy: breedingStages[0] ? breedingStages[0].SelectionStrategy : {
                QualityTraits: [],
                AgronomicTraits: [],
                MorphologicalTraits: [],
                PhenologicalTraits: [],
                AbioticStressTraits: [],
                BioticStressTraits: []
            },
        }
        dispatch(createBreedingStage(initialBreedingStageData))
    }

    const handleRefreshBreedingStage = () => {
        dispatch(refreshBreedingStagesTable(breedingSchemeId, poolId))
    }

    const handleExportToCSV = async () => {        
        await dispatch({ type: LOADING_BREEDING_STAGE_EXPORT })
        let table = document.getElementsByClassName("MuiTable-root");
        setTimeout(async () => {
            await TableToExcel.convert(table[0], {
                name: `${breedingScheme.name}.xlsx`,
                sheet: {
                    name: 'BreedingScheme'
                }
            });
            await dispatch({ type: REMOVE_LOADING_BREEDING_STAGE_EXPORT })
        }, 1500)
    }

    // const handleExportToPDF = () => {
    //     const input = document.getElementById('breedingStageTable');
    //     html2canvas(input, {
    //         logging: false,
    //         scale: 1,
    //         width: input.getElementsByTagName("table")[0].offsetWidth,
    //         windowWidth: input.getElementsByTagName("table")[0].offsetWidth,
    //     })
    //         .then((canvas) => {
    //             const imgData = canvas.toDataURL('image/png');
    //             const elementWidth = input.getElementsByTagName("table")[0].offsetWidth * 0.265 //Returns value in (mm)                                    
    //             const elementHeight = input.offsetHeight * 0.265 //Returns value in (mm)
    //             const pdf = new jsPDF({
    //                 orientation: "landscape",
    //                 format: [elementWidth, elementHeight]
    //             });
    //             pdf.addImage(imgData, 'JPEG', 0, 0);
    //             pdf.save(`${breedingScheme.name}.pdf`);
    //         })
    // }

    const handleAddQualityTrait = () => {
        dispatch(openAddTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            traitType: "Quality",
        }))
    }
    const handleAddAgronomicTrait = () => {
        dispatch(openAddTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            traitType: "Agronomic",
        }))
    }
    const handleAddMorphologicalTrait = () => {
        dispatch(openAddTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            traitType: "Morphological",
        }))
    }
    const handleAddPhenologicalTrait = () => {
        dispatch(openAddTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            traitType: "Phenological",
        }))
    }
    const handleAddAbioticStressTrait = () => {
        dispatch(openAddTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            traitType: "AbioticStress",
        }))
    }
    const handleAddBioticStressTrait = () => {
        dispatch(openAddTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            traitType: "BioticStress",
        }))
    }

    const handleAddGenotypingTechnology = () => {
        dispatch(openAddGenotypeTechnology({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            technology: ""
        }))
    }

    const handleConfirmRemoveTechnology = (id) => {
        dispatch(confirmRemoveTechnology(id, poolId))
    }

    const handleAddApplication = (technologyGroupId) => {
        dispatch(addApplication(technologyGroupId, breedingSchemeId, poolId))
    }

    const handleConfirmRemoveApplication = (id) => {
        dispatch(confirmRemoveApplication(id, poolId))
    }

    const handleAddMarkerTraits = () => {
        dispatch(openAddMarkerTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            noOfMarkersAssisted: 0
        }))
    }

    const handleConfirmRemoveMarkerTraits = (id) => {
        dispatch(confirmRemoveMarkerTraits(id, poolId))
    }

    const handleAddPhenotypeTraits = () => {
        dispatch(openAddPhenotypeTraits({
            BreedingSchemeId: breedingSchemeId,
            PoolId: poolId,
            TraitCatalogId: "",
            phenotypeCondition: "",
            phenotypeTypeOfExpUnit: "",
            phenotypeLength: "",
            phenotypeWidth: "",
            phenoNoOfPlantsPerExpUnit: 0,
            phenotypeNoOfLocations: 0,
            phenotypeNoOfRepPerLocation: 0,
        }))
    }

    const handleConfirmRemovePhenotypeTraits = (id) => {
        dispatch(confirmRemovePhenotypeTraits(id, poolId))
    }

    //Arguments passed are newCellValue, oldCellValue, rowData, columnDef
    const handleSubmit = async (newValue, oldValue, rowData, columnDef) => {
        //Update the rowData column field with the new value. This ensures an updated data to be sent for calculations                        
        rowData[columnDef.field] = newValue.replace(/,/g, '')
        const objKeys = columnDef.field.split(".")
        const updatedField = objKeys.pop()
        const updatedBreedingStage = {
            id: rowData[objKeys.join(".") + ".id"],
            [updatedField]: newValue.replace(/,/g, ''),
            BreedingSchemeId: breedingSchemeId,
            PoolId: rowData.PoolId,
            modelName: columnDef.modelName,
            breedingStageRecord: rowData,
            productType: breedingScheme.productType
        }
        await dispatch(updateBreedingStage(updatedBreedingStage))
    }

    //Arguments passed are Traits GroupId, TraitName and CostForPhenotypingTrai1t
    const handleOpenUpdateTraits = (id, columnName, value) => {
        dispatch(openUpdateTraits(id, breedingSchemeId, columnName, value))
    }

    const handleConfirmRemoveTraits = (id) => {
        dispatch(confirmRemoveTraits(id, poolId))
    }

    const handleOpenQuickNote = () => {
        dispatch(openQuickNote({
            PoolId: poolId
        }))
    }

    const handleSortBreedingStage = (newOrderNo, previousOrderNo, BreedingSchemeId, PoolId) => {
        dispatch(orderBreedingStage({ newOrderNo, previousOrderNo, BreedingSchemeId, PoolId }))
    }

    const tableData = breedingStages.length && flattenBreedingSchemeData(breedingStages, breedingStageColumns.hiddenColumns, breedingSchemePermission, pipelineSettings)
    const classes = useStyles()
    return (
        <Box id="breedingStageTable" className={classes.table}>
            <MaterialTable
                tableRef={tableRef}
                title={
                    <>
                        {/* {
                            breedingSchemePermission === 'VIEWER' ?
                                <Tooltip title="Viewer [readonly access]" placement="top">
                                    <VisibilityIcon color="primary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                </Tooltip> :
                                <Tooltip title="Editor [read/write access]" placement="top">
                                    <EditIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                </Tooltip>
                        } */}
                        <Typography variant="subtitle2" style={{ display: "none" }}>
                            BREEDING SCHEME FOR PIPELINE [<Typography component="span" style={{ fontSize: "12px" }} color="error" variant="inherit"> FILL FROM LEFT TO RIGHT (PLANT DEVELOPMENT), AND ROW BY ROW (BREEDING STAGES) </Typography>]</Typography>
                    </>
                }
                columns={tableData.columns}
                icons={TableIcons}
                localization={{
                    // toolbar: {
                    //     exportCSVName: "Export to Excel"
                    // }
                }}
                components={{
                    Header: headerProps => (
                        <TableHead>
                            <BreedingStageColumns
                                data={sortedColumnObj}
                                pipelineConst={pipelineSettings}
                                rowDepth={3}
                                hiddenColumns={breedingStageColumns.hiddenColumns}
                                handleAddQualityTrait={handleAddQualityTrait}
                                handleAddAgronomicTrait={handleAddAgronomicTrait}
                                handleAddMorphologicalTrait={handleAddMorphologicalTrait}
                                handleAddPhenologicalTrait={handleAddPhenologicalTrait}
                                handleAddAbioticStressTrait={handleAddAbioticStressTrait}
                                handleAddBioticStressTrait={handleAddBioticStressTrait}
                                handleOpenUpdateTraits={handleOpenUpdateTraits}
                                handleConfirmRemoveTraits={handleConfirmRemoveTraits}
                                handleAddGenotypingTechnology={handleAddGenotypingTechnology}
                                handleConfirmRemoveTechnology={handleConfirmRemoveTechnology}
                                handleAddApplication={handleAddApplication}
                                handleConfirmRemoveApplication={handleConfirmRemoveApplication}
                                handleAddMarkerTraits={handleAddMarkerTraits}
                                handleConfirmRemoveMarkerTraits={handleConfirmRemoveMarkerTraits}
                                handleAddPhenotypeTraits={handleAddPhenotypeTraits}
                                handleConfirmRemovePhenotypeTraits={handleConfirmRemovePhenotypeTraits}
                                permission={breedingSchemePermission}
                            />
                        </TableHead>),
                    Toolbar: toolbarProps => (
                        <div className="breeding-stage-toolbar">
                            <MTableToolbar {...toolbarProps} />
                            <BreedingStageToolbar
                                breedingSchemeId={breedingSchemeId}
                                poolId={poolId}
                                breedingPipelineId={breedingScheme.BreedingPipelineId}
                                handleAddBreedingStage={handleAddBreedingStage}
                                handleRefreshBreedingStage={handleRefreshBreedingStage}
                                handleExportToCSV={handleExportToCSV}
                                // handleExportToPDF={handleExportToPDF}
                                handleOpenQuickNote={handleOpenQuickNote}
                                permission={breedingSchemePermission}
                            />
                        </div>
                    ),
                    Cell: cellProps => cellProps.columnDef.editable === 'never' ?
                        cellProps.columnDef.field === "stageNo" ?
                            <TableCell style={{ ...cellProps.columnDef.cellStyle, color: "rgba(0,0,0,0.7)", border: "1px solid #b3b3b3" }}>
                                <TableRowOrder
                                    id={breedingSchemeId}
                                    poolId={poolId}
                                    order={cellProps.rowData.stageNo}
                                    total={tableData.data ? tableData.data.length : 0}
                                    sort={handleSortBreedingStage}
                                    isDisabled={breedingSchemePermission === "VIEWER"}>
                                    {cellProps.rowData.stageNo}
                                </TableRowOrder>
                            </TableCell> :
                            <GridCell handleSubmit={handleSubmit} {...cellProps} /> : <GridEditableCell handleSubmit={handleSubmit} {...cellProps} />,
                    Pagination: paginationProps => <>
                        {/* <TableCell style={{ float: "left", padding: "8px 16px" }}>
                            <BreedingStageSummary {...tableData.totalCosts} />
                        </TableCell> */}
                        <TablePagination {...paginationProps} style={{ float: "right", width: "auto" }} /></>
                }}
                options={{
                    search: false,
                    columnsButton: false,
                    exportButton: false,
                    padding: "dense",
                    pageSize: 50,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    pageSizeOptions: [5, 20, 50, 100],
                    addRowPosition: "last",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                }}
                data={tableData.data}
                actions={
                    [
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Duplicate Breeding Stage",
                            isFreeAction: false,
                            disabled: breedingSchemePermission === "VIEWER",
                            onClick: (event) => dispatch(confirmDuplicateBreedingStage(rowData.id, poolId))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Breeding Stage",
                            disabled: breedingSchemePermission === "VIEWER",
                            isFreeAction: false,
                            onClick: (event) => dispatch(confirmRemoveBreedingStage(rowData.id, poolId))
                        })
                    ]}
            />
        </Box >
    )
}

export default BreedingStage;